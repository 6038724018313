<template>
	<div class="informationperson">
		<v-card class="bg-primary text-light text-left">担当者情報</v-card>
	</div>
</template>

<script>
import store from '../store';
export default {
	name: 'Informationperson',
	computed: {
		getname() {
			return store.getters.name;
		},
	},
};
</script>
