<template>
	<div class="notfound">
		<h1>ページが見つかりません</h1>
		<p>お探しのページは、移動または削除された可能性があります。</p>
	</div>
</template>

<script>
export default {
	name: 'NotFound',
}
</script>