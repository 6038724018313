<template>
  <v-app>
    <div class="button">
      <v-card class="bg-primary text-light text-left">メッセージ</v-card>
    </div>
    <v-expansion-panels v-if="mobile">
      <v-expansion-panel>
        <v-expansion-panel-title> 使用方法 ▼ </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-card id="explanation" class="text-left">
            <ul>
              <li>メッセージ機能</li>
            </ul>
          </v-card>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card v-else id="explanation" class="text-left">
      <ul>
        <li>メッセージ機能</li>
      </ul>
    </v-card>
    <!-- <ControlTablemobile v-if="mobile" />
    <ControlTable v-else /> -->
  </v-app>
</template>

<script>
import store from "../store";
import ControlTable from "../components/LiveBtnControll/ControlTable.vue";
import ControlTablemobile from "../components/LiveBtnControll/ControlTable_mobile.vue";
import CMethod from "../lib/CommonMethods.js";
export default {
  name: "Info",
  components: { ControlTable, ControlTablemobile },
  data() {
    return {
      mobile: false,
    };
  },
  mounted() {
    // モバイルからのアクセスの場合、表示するコンポーネントを切り替える
    this.mobile = CMethod.isMobile();
  },
};
</script>
<style>
#explanation {
  margin: 20px;
  padding: 25px 0 10px 0;
  width: fit-content;
  background-color: #e6f1ff;
}
ul {
  list-style-type: circle;
}
</style>
