<template>
	<v-btn @click="dialogOpen = true" color="lime">案件切替</v-btn>
	<v-dialog v-model="dialogOpen">
		<v-card>
			<v-card-title>
				<div>案件切替</div>
			</v-card-title>
			<v-card-text>
				<p>切り替える案件名を選択して「送信」ボタンをクリックして下さい。</p>
				<select v-model="selected">
					<option disabled value="">リストから選択</option>
					<option v-for="project in this.$store.getters.allowedProjects">
						{{ project.projectName }}
					</option>
				</select>
				<span>　Selected: {{ selected }}</span>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn @click="dialogOpen = false" class="btn-secondary">閉じる</v-btn>
				<v-btn @click="confirm" class="btn-primary">送信</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import store from '@/store/index.js';

export default {
	data() {
		return {
			dialogOpen: false,
			selected: '',
		};
	},
	methods: {
		confirm() {
			const project = this.$store.getters.allowedProjects.find((v) => v.projectName == this.selected);
			store.commit('currentProject', project);
			this.dialogOpen = false;
			alert('[' + this.selected + ']で送信しました');
			this.$router.push('/');
		},
	},
};
</script>
