<template>
  <Loading v-if="loading"></Loading>
  <div v-else id="app">
    <div class="selectarea">
      <!--取得年選択-->
      <img
        class="icons"
        src="./icons/calendar.svg"
        width="30"
        height="30"
        alt="calendar"
      />
      <select
        v-model="selectedyear"
        class="select_yearmanth form-select d-inline-block"
        @change="selectYear"
      >
        <option hidden value="" selected>-----</option>
        <option v-for="year in years" v-bind:value="year" v-bind:key="year">
          {{ year + "年" }}
        </option>
      </select>
      <img
        class="icons"
        src="./icons/chevron-right.svg"
        width="30"
        height="30"
        alt="chevron"
      />

      <!--取得月選択-->
      <select
        v-model="selectedmonth"
        class="select_yearmanth form-select d-inline-block"
        @change="selectMonth"
      >
        <option hidden value="" selected>-----</option>
        <option v-for="month in months" v-bind:value="month" v-bind:key="month">
          {{ month + "月" }}
        </option>
      </select>

      <!--レポート選択-->
      <img
        class="icons"
        src="./icons/file-text.svg"
        width="30"
        height="30"
        alt="calendar"
      />
      <select
        v-model="selectedreport"
        @change="selectReport"
        class="select_report form-select d-inline-block"
      >
        <option hidden value="" selected>-----</option>
        <option
          v-for="list in reportlists"
          v-bind:value="list"
          v-bind:key="list"
        >
          {{ fromatReportname(list) }}
        </option>
      </select>

      <!--表示 グラフ CSV ボタン-->
      <v-btn
        class="buttons"
        v-show="selectedreport != '' && selectedmonth != ''"
        @click="showReport"
        >レポート表示</v-btn
      >
      <v-btn class="buttons" v-if="graphbtnflg && graphuse" @click="showGraph"
        >表／グラフ</v-btn
      >
      <v-btn
        class="buttons"
        v-show="csvbtnflg && selectedreport != '' && selectedmonth != ''"
        @click="downloadCSV"
        >CSV</v-btn
      >
    </div>

    <!--レポートラベル-->
    <div
      v-show="showarea == 'table' || showarea == 'graph'"
      class="mt-3 bg-light text-left"
    >
      <span id="reportlabel"
        >{{ selectedyear }} 年 {{ selectedmonth }}月　アクセスレポート　
        {{ selectedreportName }}</span
      >
    </div>
    <!--合計テーブル-->
    <div class="tablescroll table-responsive" v-on:scroll="scrollsync">
      <table
        v-show="showarea == 'graph' || showarea == 'table'"
        id="total_table"
        class="reporttable table table-bordered table-hover"
      >
        <thead>
          <tr>
            <td
              class="fw-bold"
              v-for="t_firstrow in t_firstrows"
              :key="t_firstrow"
            >
              {{ t_firstrow }}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="date" v-for="t_row in t_rows" :key="t_row">
              {{ t_row }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!--詳細テーブル-->
    <div class="tablescroll table-responsive" v-on:scroll="scrollsync">
      <table
        v-show="showarea == 'table'"
        id="detail_table"
        class="reporttable table table-bordered table-hover"
      >
        <thead>
          <tr>
            <td
              class="fw-bold"
              v-for="d_firstrow in d_firstrows"
              :key="d_firstrow"
            >
              {{ d_firstrow }}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr class="date" v-for="d_row in d_rows" :key="d_row">
            <td v-for="(value, key) in d_row" :key="value">
              {{ d_row[key] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      v-show="showarea == 'spinner'"
      id="spinner"
      class="spinner-border text-primary"
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
    <ReportGraph
      v-show="showarea == 'graph'"
      :labels="graphlabel"
      :data="graphdata"
    >
    </ReportGraph>
  </div>
</template>
<script>
import CMethod from "@/lib/CommonMethods.js";
import Loading from "../Common/Loading.vue";
import ReportGraph from "@/components/AccessReport/ReportGraph";

export default {
  name: "app",
  components: {
    Loading,
    ReportGraph,
  },
  data() {
    return {
      loading: true,
      years: [],
      months: [],
      reportdata: null,
      reportlists: [],
      selectedyear: "",
      selectedmonth: "",
      selectedreport: "",
      selectedreportName: "",
      reportids: null,
      t_firstrows: null,
      t_rows: null,
      d_firstrows: null,
      d_rows: null,
      graphlabel: null,
      graphdata: null,
      graphuse: true,
      csvbtnflg: false,
      graphbtnflg: false,
    };
  },
  methods: {
    // レポート表示
    async showReport() {
      this.showarea = "spinner";
      this.csvbtnflg = true;
      this.graphbtnflg = true;
      this.selectedreportName = this.reportids[this.selectedreport];
      var date = new Date();
      this.now =
        CMethod.formatDate(date) +
        date.getHours() +
        "時" +
        date.getMinutes() +
        "分";

      // レポートデータ初期化
      this.t_firstrows = null;
      this.t_rows = null;
      this.d_firstrows = null;
      this.d_rows = null;

      // レポート詳細データ取得
      var response = await this.$StarkManager
        .getMember("Morgan")
        .getReportDetail(this.selectedreport);

      this.showarea = "table";
      // 合計テーブルデータ
      this.t_firstrows = response.data.totalreport.firstrow;
      this.t_rows = response.data.totalreport.rows;

      // 詳細テーブルデータ
      this.d_firstrows = response.data.report.firstrow;
      this.d_rows = response.data.report.rows;
    },
    // 年選択時処理
    selectYear() {
      this.csvbtnflg = false;
      this.graphbtnflg = false;
      this.selectedmonth = "";
      this.selectedreport = "";
      this.months = Object.keys(this.reportdata[this.selectedyear]);
    },
    // 月選択時処理
    selectMonth() {
      this.csvbtnflg = false;
      this.graphbtnflg = false;
      this.selectedreport = "";
      this.reportlists = this.reportdata[this.selectedyear][this.selectedmonth];
    },
    // 月選択時処理
    selectReport() {
      this.csvbtnflg = false;
      this.graphbtnflg = false;
    },
    // レポートIDより和名を取得
    fromatReportname(listname) {
      return this.reportids[listname];
    },
    // グラフ表示
    showGraph() {
      if (this.showarea == "table") {
        this.showarea = "graph";
        this.graphlabel = this.t_firstrows.slice(1, -1);
        this.graphdata = this.t_rows.slice(1, -1);
      } else {
        this.showarea = "table";
        this.graphlabel = [];
        this.graphdata = [];
      }
    },
    // テーブルスクロール同期
    scrollsync(event) {
      let target = event.target;
      target.parentElement.querySelector(".tablescroll").scrollLeft =
        target.scrollLeft;
    },
    // CSV出力
    downloadCSV() {
      var csv = "\ufeff";
      this.t_firstrows.forEach((el) => {
        var line = el + ",";
        csv += line;
      });
      csv += "\n";
      this.t_rows.forEach((el) => {
        var line = el + ",";
        csv += line;
      });
      csv += "\n" + "\n";
      this.d_firstrows.forEach((el) => {
        var line = el + ",";
        csv += line;
      });
      csv += "\n";
      this.d_rows.forEach((el) => {
        var line = el + "," + "\n";
        csv += line;
      });
      let blob = new Blob([csv], { type: "text/csv" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = this.selectedreportName + ".csv";
      link.click();
    },
    async getReports() {
      // レポートリスト取得
      var response = await this.$StarkManager.getMember("Morgan").getReports();

      this.loading = false;
      this.reportdata = response.data.data;
      this.reportids = response.data.reportIDs;

      //コンボボックスの状態初期化
      this.years = Object.keys(this.reportdata);
      this.selectedyear = this.years.slice(-1)[0];
      this.months = Object.keys(this.reportdata[this.selectedyear]);
      this.selectedmonth = this.months.slice(-1)[0];
      this.reportlists = this.reportdata[this.selectedyear][this.selectedmonth];
      this.selectedreport = this.reportlists[0];
    },
  },
  mounted() {
    // レポートリスト取得
    this.getReports();

  },
};
</script>

<style>
.selectarea {
  margin: 20px;
  display: flex;
  height: 40px;
}

.icons {
  margin: 4px 15px 0 15px;
}

.buttons {
  margin: 2px 10px 0 10px;
}

.select_yearmanth {
  width: 8% !important;
}

.select_report {
  width: 35% !important;
}

#reportlabel {
  color: #606060;
  font-weight: bold;
  margin: 0 0 0 20px;
}

.reporttable {
  margin: 20px;
  width: 1220px !important;
  font-size: 95%;
  table-layout: fixed;
}

.reporttable td {
  padding: 0.5px !important;
}

table td:last-child {
  width: 50px;
}

thead {
  background-color: #e6f1ff;
}

table tr td:nth-of-type(1) {
  width: 150px;
  word-break: break-all;
  text-align: center;
}

#spinner {
  display: block;
  margin: auto;
  margin-top: 40px;
}

@media screen and (max-width: 750px) {
  .selectarea {
    display: grid;
    height: 331px;
  }
  .select_report {
    width: 92px;
    font-size: 15px;
  }
}
</style>
