<template>
  <div id="app">
    <LineChart
      :chartData="chartData"
      :options="chartOptions"
      style="width: 80.7%"
    />
  </div>
</template>

<script>
import { Chart, registerables } from "chart.js";
import { LineChart } from "vue-chart-3";
Chart.register(...registerables);

export default {
  name: "Graph",
  props: ["labels", "data"], // ReportTableから取得したグラフデータ
  components: {
    LineChart,
  },
  computed: {
    // チャート データセット
    chartData() {
      return {
        labels: this.labels,
        datasets: [
          {
            label: "日毎集計",
            data: this.data,
            borderColor: "rgb(75, 192, 192)",
            backgroundColor: "rgb(75, 192, 192)",
          },
        ],
      };
    },
    // チャート オプション
    chartOptions() {
      return {
        borderWidth: 2,
        plugins: {
          legend: {
            display: true,
            labels: {
              font: {
                size: 16,
              },
            },
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "日付（日）",
              font: {
                size: 16,
              },
            },
            ticks: {
              font: {
                size: 16,
              },
            },
          },
          y: {
            display: true,
            beginAtZero: true, // 線描画を0から始める
            ticks: {
              precision: 0, // 少数以下の表示桁数
              font: {
                size: 16,
              },
            },
          },
        },
      };
    },
  },
};
</script>
<style scoped>
#app {
  display: flex;
  width: 100%;
  height: 460px;
  margin: 50px 0 0 20px;
}
</style>
