import { createRouter, createWebHashHistory } from "vue-router";
import Blank from "../views/Blank.vue";
import Welcome from "../views/Welcome.vue";
import Announcement from "../views/Announcement.vue";
import Informationurl from "../views/Informationurl.vue";
import Informationperson from "../views/Informationperson.vue";
import AccessReport from "../views/AccessReport.vue";
import LiveBtnControl from "../views/LiveBtnControl.vue";
import Message from "../views/Message.vue";
import SignInView from "../views/SignInView.vue";
import store from "../store";
import NotFound from "../views/NotFound.vue";
import Log from "../views/Log.vue";

const routes = [
  { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFound },
  {
    path: "/blank",
    name: "Blank",
    component: Blank,
    beforeEnter: (to, from, next) => {
      store.getters.isSignin ? next() : next("/signin");
    },
  },
  {
    path: "/",
    name: "Welcome",
    component: Welcome,
    beforeEnter: (to, from, next) => {
      store.getters.isSignin ? next() : next("/signin");
    },
  },
  {
    path: "/announcement",
    name: "Announcement",
    component: Announcement,
    beforeEnter: (to, from, next) => {
      store.getters.isSignin ? next() : next("/signin");
    },
  },
  {
    path: "/informationurl",
    name: "Informationurl",
    component: Informationurl,
    beforeEnter: (to, from, next) => {
      store.getters.isSignin ? next() : next("/signin");
    },
  },
  {
    path: "/informationperson",
    name: "Informationperson",
    component: Informationperson,
    beforeEnter: (to, from, next) => {
      store.getters.isSignin ? next() : next("/signin");
    },
  },
  {
    path: "/accessreport",
    name: "AccessReport",
    component: AccessReport,
    beforeEnter: (to, from, next) => {
      store.getters.isSignin ? next() : next("/signin");
    },
  },
  {
    path: "/livebtncontrol",
    name: "LiveBtnControl",
    component: LiveBtnControl,
    beforeEnter: (to, from, next) => {
      store.getters.isSignin ? next() : next("/signin");
    },
  },
  {
    path: "/Message",
    name: "Message",
    component: Message,
    beforeEnter: (to, from, next) => {
      store.getters.isSignin ? next() : next("/signin");
    },
  },
  {
    path: "/log",
    name: "Log",
    component: Log,
    beforeEnter: (to, from, next) => {
      store.getters.isSignin ? next() : next("/signin");
    },
  },
  {
    path: "/signin",
    name: "SignInView",
    component: SignInView,
    beforeEnter: (to, from, next) => {
      store.getters.isSignin ? next("/") : next();
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
