import logger from "@/lib/Logger.js";
import store from "@/store/index.js";
import axios from "axios";
/**
 *Morganへアクセスするクラスです。
 */
export default class MorganConnector {
  constructor(config) {
    this.config = config;
    this.stark = null;
  }

  /**
   *StarkManagerへの参照をセットします。
   */
  setStark(stark) {
    this.stark = stark;
  }

  /**
   *ログインユーザーに紐づく案件情報を取得し、
   *取得した案件情報をローカルストレージに保存します。
   */
  async fetchProject() {
    logger.showConsole("fetchProject", "start");

    var response = await this.getMorganResponse("Get", "Project", "");

    if (response != null) {
      logger.showConsole("fetchProject", response);
      store.commit("allowedProjects", response.data.projects);
      store.commit("currentProject", response.data.defaultProject);
    } else {
      alert("案件情報の取得に失敗しました。");
    }
  }

  /**
   * 顧客情報を取得し、取得した顧客情報をローカルストレージに保存します。
   */
  async fetchClient() {
    logger.showConsole("fetchClient", "start");

    var projectId =
      store.getters.currentProject.projectId == undefined
        ? ""
        : store.getters.currentProject.projectId;

    var response = await this.getMorganResponse("Get", "Client", projectId);

    if (response != null) {
      logger.showConsole("fetchClient", response.data);
      store.commit("Client", response.data);
    } else {
      logger.showConsole("fetchClient", "failed");
    }
  }

  /**
   * サイドバーに表示するメニュー項目を取得し、
   * 取得したメニュー項目をローカルストレージに保存します。
   */
  async fetchMenu() {
    logger.showConsole("fetchMenu", "start");
    var projectId =
      store.getters.currentProject.projectId == undefined
        ? ""
        : store.getters.currentProject.projectId;

    var response = await this.getMorganResponse("Get", "Menu", projectId);

    if (response != null) {
      logger.showConsole("fetchMenu", response.data);
      store.commit("sideBarList", response.data);
    } else {
      logger.showConsole("fetchMenu", "failed");
    }
  }

  /**
   * 操作中の案件に紐づくレポート一覧を取得します。
   *@return reportList レポート一覧を返します。
   */
  async getReports() {
    logger.showConsole("getReports", "start");
    var reportList = await this.getMorganResponse(
      "Get",
      "Report",
      store.getters.currentProject.projectId
    );

    if (reportList != null) {
      logger.showConsole("getReports", reportList.data);
      return reportList;
    } else {
      logger.showConsole("getReports", "failed");
    }
  }

  /**
   * アクセスレポート画面で指定したレポートの値を取得します。
   *@param targetreport 指定したレポート
   *@return reportDetail レポートの値を返します。
   */
  async getReportDetail(targetreport) {
    logger.showConsole("getReportDetail", "start");
    var reportDetail = await this.getMorganResponse(
      "Get",
      "Report",
      store.getters.currentProject.projectId + "/reports/" + targetreport
    );
    if (reportDetail != null) {
      logger.showConsole("getReportDetail", reportDetail.data);
      return reportDetail;
    } else {
      logger.showConsole("getReports", "failed");
    }
  }

  /**
   * 操作中の案件に紐づくイベント一覧を取得します。
   *@return 操作中案件に紐づくイベント一覧を返します。
   */
  async getEvent() {
    logger.showConsole("getEvent", "start");
    var eventList = await this.getMorganResponse(
      "Get",
      "Event",
      store.getters.currentProject.projectId
    );
    if (eventList != null) {
      logger.showConsole("getEvent", eventList.data);
      return eventList;
    } else {
      logger.showConsole("getEvent", "failed");
    }
  }

  /**
   * ライブボタン切替画面で指定したイベントのライブボタン状態を更新します。
   *@param body イベントへのリクエスト内容
   */
  async postEvent(body) {
    logger.showConsole("postEvent", "start");
    var result = await this.getMorganResponse(
      "Post",
      "Event",
      store.getters.currentProject.projectId + "/events",
      body
    );
    logger.showConsole("postEvent", result);
    // 更新結果文字列を返します。
    return result.statusText;
  }

  /**
   * 呼び出すURLを生成します。
   *@param target 呼び出すコントローラーパス
   *@param appendix パラメーター
   *@return WebAPIを呼び出すためのURLを返します。
   */
  buildUrl(target, appendix) {
    var baseurl = this.config.endpointSuffix;
    return baseurl + "/" + target + "/" + appendix;
  }

  /**
   * リクエストヘッダを構成します。
   * 同じヘッダ内容を複数記載しなくて良いように実装しています。
   *@param  indivHeader リクエストごとに個別で付与するヘッダ
   *@return indivHeader 各リクエスト共通で付与するヘッダを加えて返します。
   */
  mergeCommonHeader(indivHeader) {
    //ここで共通のヘッダを追加する
    indivHeader.headers["Content-Type"] = "application/json";
    return indivHeader;
  }

  /**
   * Moganへアクセスし、その応答結果を取得します。
   *@param method メソッドの識別名
   *@param target 呼び出すコントローラーパス
   *@param param パラメーター
   *@param body リクエストボディ
   *@return response Morganからの応答結果を返します。
   */
  async getMorganResponse(method, target, param, body) {
    var token = await this.stark.getAuthManager().getToken();
    switch (method) {
      // Gst リクエスト
      case "Get":
        return await axios
          .get(
            this.buildUrl(target, param),
            this.mergeCommonHeader({
              headers: {
                Authorization: `Bearer ${token.accessToken}`,
              },
            })
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            console.log(error);
            return null;
          });

      // Post リクエスト
      case "Post":
        return await axios
          .post(
            this.buildUrl(target, param),
            body,
            this.mergeCommonHeader({
              headers: {
                Authorization: `Bearer ${token.accessToken}`,
              },
            })
          )
          .then(function (response) {
            return response;
          })
          .catch(function (error) {
            console.log(error);
          });
    }
  }
}
