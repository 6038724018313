<template>
  <p>GS2マイページ</p>
  <SignInButton />
</template>

<script>
import SignInButton from "@/components/Common/SignInButton.vue";

export default {
  name: "SignInView",
  components: {
    SignInButton,
  },
};
</script>
