<template>
  <Loading v-if="loading"></Loading>
  <div v-else id="app">
    <div class="table-responsive">
      <table id="control_table" class="table table-bordered">
        <thead>
          <tr id="control_table_label" class="control_table_row">
            <th>会議室</th>
            <th colspan="2">ライブボタン状態</th>
            <th class="twolines">ライブ<br />再生ページ</th>
            <th>開催日・会議名</th>
            <th class="twolines">公開サイト<br />トップ</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, key) in eventinfo"
            :key="key"
            v-bind:class="{ live_valid: item.eventActivePublic }"
          >
            <td class="room text-left">
              {{ item.roomLabel }}　({{ item.roomTitle }})
            </td>
            <td class="status text-center">
              <span v-if="item.eventActivePublic" class="valid bold"
                >有効
                <img
                  src="./icons/camera-video.svg"
                  width="16"
                  height="16"
                  alt="video-on"
              /></span>
              <span v-else
                >無効
                <img
                  src="./icons/camera-video-off.svg"
                  width="16"
                  height="16"
                  alt="video-off"
                />
              </span>
            </td>
            <td class="control text-center">
              <v-btn
                v-if="item.eventActivePublic"
                type="button"
                class="control_button btn btn-secondary"
                v-on:click="liveOff(item)"
              >
                無効にする
              </v-btn>
              <v-btn
                v-else
                type="button"
                class="control_button btn btn-primary"
                v-on:click="liveOn(item)"
                data-bs-toggle="modal"
                data-bs-target="#controlModal"
              >
                有効にする
              </v-btn>
            </td>
            <td class="preview">
              <form
                name="{{item.roomId}}"
                action=""
                method="post"
                target="_blank"
              >
                <input type="hidden" name="AccessToken" value="" />
              </form>
              <a
                title="再生ページを表示します"
                href="javascript:void(0);"
                @click="openLivePage(item)"
                class="preview_link"
              >
                <img
                  src="./icons/front.svg"
                  width="16"
                  height="16"
                  alt="再生ページを表示します"
                />
              </a>
            </td>
            <td class="event text-left">
              {{ formatDate(item.eventDate) }}
              <br />{{ item.eventSeriesLabel }}
            </td>
            <td class="preview">
              <a
                title="公開サイトを表示します"
                href="javascript:void(0);"
                v-on:click="openTopPage(item)"
                class="preview_link"
              >
                <img
                  src="./icons/front.svg"
                  width="16"
                  height="16"
                  alt="公開サイトを表示します"
                />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="controlModal"
      tabindex="-1"
      aria-labelledby="controlModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="controlModalLabel">確認</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body text-left">
            会議室： {{ modalitem.roomLabel }}　({{
              modalitem.roomTitle
            }})<br />

            開催日： {{ formatDate(modalitem.eventDate) }}<br />
            会議名： {{ modalitem.eventSeriesLabel }}<br />

            <div v-if="this.date != modalitem.eventdate" class="mt-2 mb-2">
              <span class="text-dark fw-bold bg-primary bg-opacity-10">
                <img
                  src="./icons/exclamation-triangle-fill.svg"
                  width="16"
                  height="16"
                  alt="exclamation" />
                この会議室に割り当てられている会議は、本日開催の会議ではありません。<br
              /></span>
            </div>
            ライブボタンを
            <span class="text-primary fw-bold">有効</span> にします。<br />
            続行しますか？<br />
          </div>
          <div class="modal-footer">
            <v-btn
              v-on:click="flagUpdate"
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
            >
              はい
            </v-btn>
            <v-btn
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              いいえ
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CMethod from "@/lib/CommonMethods";
import Loading from "../Common/Loading.vue";
import LiveBtnControl from "../../views/LiveBtnControl.vue";
import store from "@/store/index.js";

export default {
  components: { Loading, LiveBtnControl },
  data() {
    return {
      loading: true,
      eventinfo: [],
      modalitem: [],
      select: null,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },
  methods: {
    // 再生ページを開く
    openLivePage(e) {
      // ボタン状態に応じて遷移先を制御
      if (e.eventActivePublic) {
        window.open(
          store.getters.publicUrl + "?tpl=play_live&room_id=" + e.roomId,
          "_blank"
        );
      } else {
        document.forms[e.roomId].AccessToken.value = store.getters.token;
        document.forms[e.roomId].action =
          store.getters.privateUrl + "?tpl=play_live&room_id=" + e.roomId;
        document.forms[e.roomId].submit();
      }
    },
    // トップページを開く
    openTopPage() {
      window.open(store.getters.publicUrl, "_blank");
    },
    // ボタン状態を有効にする
    liveOn(e) {
      this.select = e;
      this.modalitem = e;
    },
    // ボタン状態を無効にする
    liveOff(e) {
      this.select = e;
      this.flagUpdate();
    },
    // イベント状態を更新する
    async flagUpdate() {
      // ボタン状態変更
      this.select.eventActivePublic = !this.select.eventActivePublic;

      //要求（イベントIDと制御フラグを送信）
      const body = [
        {
          eventId: this.select.eventId,
          eventActivePublic: this.select.eventActivePublic,
        },
      ];

      var result = await this.$StarkManager.getMember("Morgan").postEvent(body);

      // 更新失敗時
      if (result != "OK") {
        // 状態を戻す
        this.select.eventActivePublic = !this.select.eventActivePublic;
        alert("ライブボタンの変更ができませんでした。");
      }
    },
    // 日付フォーマット変換
    formatDate(date) {
      return CMethod.formatDate(date);
    },
    // イベント一覧取得
    async getEvent() {
      var response = await this.$StarkManager.getMember("Morgan").getEvent();
      this.loading = false;
      this.eventinfo = response.data;
    },
  },
  mounted() {
    // イベント一覧取得
    this.getEvent();
  },
};
</script>

<style>
#control_table {
  /* width: 61%; */
  width: 1000px;
  /* width: auto; */
  margin: 20px;
}

.twolines {
  width: 97px;
}

.room {
  width: auto !important;
  /* width: 305px !important; */
}

.status {
  width: auto;
  width: 2.5em;
  white-space: nowrap;
}

button.control_button {
  font-size: 13px;
}

.preview {
  /* width: 4em;
  position: relative; */
}

.preview_icon {
  /* width: 15px;
  height: 15px; */
}

.event {
  /* width: 305px !important; */
  white-space: nowrap;
}

.live_valid {
  font-weight: bold;
  background-color: #e6f1ff;
}

.valid {
  color: blue;
}

.bold {
  font-weight: bold;
}

.hidden {
  display: none;
}

.modal-open {
  padding-right: 0 !important;
}
</style>
