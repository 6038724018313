<template>
  <div id="FOOTER">
    <div id="Copyright">Copyright © J・FIT Co.,LTD All rights reserved.</div>
    <div id="Designed">Designed by J・FIT</div>
  </div>
</template>

<script>
</script>

<style>
div#FOOTER {
  border-top: 2px solid #999999;
  margin: 0px;
  padding: 0px;
}
div#FOOTER div#Copyright {
  margin: 0px;
  padding: 5px 10px;
  text-align: left;
  font-size: 85%;
  font-weight: normal;
  font-style: italic;
  color: #666666;
  float: left;
}
div#FOOTER div#Designed {
  margin: 0px;
  padding: 5px 10px;
  text-align: right;
  font-size: 85%;
  font-weight: normal;
  font-style: italic;
  color: #666666;
}
</style>