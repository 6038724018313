import { PublicClientApplication } from "@azure/msal-browser";
import store from "@/store/index.js";
import router from "@/router/index.js";

/**
 *認証管理を行うためのクラス（Wakanda）です。
 *認証トークンを取得するためのクラスインスタンスと認証結果を保持します。
 */
export default class AuthManager {
  constructor(msalConfig) {
    this.app = new PublicClientApplication(msalConfig);
    this.signInResult = {};
  }
  /**
   * サインインしたユーザーの認証情報をAuthManagerにセットします。
   * @param value サインインしたユーザー情報
   */
  setSignInResult(value) {
    this.signInResult = value;
  }

  /**
   *サインインするためのポップアップ画面を表示します。
   */
  SignInPopup() {
    this.app
      .loginPopup()
      .then((result) => {
        console.log("SignIn success", result);
        store.commit("SignIn", result);
        this.setSignInResult(result);
        router.push(
          "/blank",
          () => {},
          () => {}
        );
      })
      .catch((error) => {
        console.log("SignIn error", error);
      });
  }

  /**
   *サインアウトした状態であることをローカルストレージに保存します。
   */
  SignOut() {
    store.commit("SignOut");
    this.app.logoutRedirect();
  }

  /**
   *トークン情報を取得します。
   * @return TokenResponse  acquireTokenSilent()によって取得したトークン情報を返します。
   */
  async getToken() {
    try {
      const account = this.app.getAllAccounts()[0];
      const TokenRequest = {
        account: account,
        // scopeは環境変数から取得
        //TODO scopeはgetTokenを呼び出す時に指定するように修正する。
        scopes: [
          process.env.VUE_APP_API_SCOPE_READ,
          process.env.VUE_APP_API_SCOPE_WRITE,
        ],
      };

      //トークン要求の試行
      var TokenResponse = await this.app.acquireTokenSilent(TokenRequest);
      return TokenResponse;
    } catch (error) {
      console.log(error);
    }
  }
}
