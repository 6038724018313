const config = {
  auth: {
    clientId: "3aea97e7-b222-4c7f-859e-34ad4401d485",
    authority:
      "https://jaspcim.b2clogin.com/jaspcim.onmicrosoft.com/B2C_1_jarvis_default_signin",
    knownAuthorities: ["jaspcim.b2clogin.com"],
  },
  cache: {
    cacheLocation: "localStorage",
  },
};

export default config;
