<template>
  <div class="report">
    <v-card class="bg-primary text-light text-left">アクセスレポート</v-card>
  </div>
  <ReportTable />
</template>

<script>
import ReportTable from "../components/AccessReport/ReportTable.vue";
export default {
  name: "Info",
  components: { ReportTable },
};
</script>
