import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    isSignin: false,
    user: {},
    info: {},
    allowedProjects: {},
    currentProject: {},
    sideBarList: {},
  },
  mutations: {
    SignIn(state, user) {
      state.isSignin = true;
      state.user = user;
    },
    SignOut(state) {
      state.isSignin = false;
      state.user = {};
      state.info = {};
      state.allowedProjects = {};
      state.currentProject = {};
      state.sideBarList = {};
    },
    Client(state, info) {
      state.info = info;
    },
    allowedProjects(state, projects) {
      state.allowedProjects = projects;
    },
    currentProject(state, project) {
      state.currentProject = project;
    },
    sideBarList(state, list) {
      state.sideBarList = list;
    },
  },
  getters: {
    isSignin: (state) => {
      return state.isSignin;
    },
    name: (state) => {
      return state.user.account.name;
    },
    token: (state) => {
      return state.user.idToken;
    },
    privateUrl: (state) => {
      return state.info.clientPrivateUrl;
    },
    publicUrl: (state) => {
      return state.info.clientPublicUrl;
    },
    allowedProjects: (state) => {
      return state.allowedProjects;
    },
    currentProject: (state) => {
      return state.currentProject;
    },
    sideBarList: (state) => {
      return state.sideBarList;
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()],
});
