<template>
  <v-btn @click="SignOut()" class="btn-primary">ログアウト</v-btn>
</template>

<script>
export default {
  name: "SignOutButton",

  data: () => ({}),
  methods: {
    SignOut() {
      this.$AuthManager.SignOut();
    },
  },
  computed: {},
};
</script>
