/**
 * ログ出力を行います。
 *コンソールに処理結果を出力します。
 *@param method 実行された処理名
 *@param value 処理結果
 */
function showConsole(method, value) {
  console.log("__LOGGER__Process：" + method + "　Result：", value);
}

export default {
  showConsole,
};
