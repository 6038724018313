import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import config from "@/config/msalConfig.js";
import AuthManager from "./lib/AuthenticationManager.js";
import StarkManager from "./lib/StarkManager.js";
import MorganConnector from "./lib/MorganConnector";
import vuetify from "./plugins/vuetify";

// Bootstrap
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap";

const app = createApp(App).use(store).use(router).use(vuetify);
app.mount("#app");
app.config.globalProperties.$AuthManager = new AuthManager({
  auth: config.auth,
  cache: config.cache,
});
app.config.globalProperties.$StarkManager = new StarkManager(
  app.config.globalProperties.$AuthManager,
  {
    Morgan: new MorganConnector({
      endpointSuffix: "https://ach-staging.stream.jfit.co.jp/api",
    }),
  }
);
