<template>
  <div>
    <Loading id="loader" />
  </div>
</template>

<script>
/**
 *ログイン後、welcomeページへ遷移する前の中間ページ(即時遷移するので表示は見えない)
 *ログインしたユーザー情報とそれに紐づく案件情報、表示するメニュー項目をここでセットします。
 */
import router from "@/router/index.js";
import Loading from "../components/Common/Loading.vue";
export default {
  name: "Blank",
  components: { Loading },
  methods: {
    /** 案件情報をセットします。*/
    fetchProject() {
      this.$StarkManager.getMember("Morgan").fetchProject();
    },
    /** 顧客情報をセットします。*/
    fetchClient() {
      this.$StarkManager.getMember("Morgan").fetchClient();
    },
    /** メニュー項目をセットします。*/
    fetchMenu() {
      this.$StarkManager.getMember("Morgan").fetchMenu();
    },
  },
  mounted() {
    this.fetchProject();
    this.fetchClient();
    this.fetchMenu();
    // 初期ページ(Welcome)へ遷移
    router.push(
      "/",
      () => {},
      () => {}
    );
  },
};
</script>
<style>
#loader {
  margin: 150px;
}
</style>
