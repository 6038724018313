<template>
  <v-btn class="btn-primary" elevation="5" @click="SignIn" v-if="!isSignin"
    >SignIn</v-btn
  >
</template>

<script>
import store from "@/store/index.js";

export default {
  name: "SignInButton",
  methods: {
    SignIn() {
      this.$AuthManager.SignInPopup();
    },
  },
  computed: {
    isSignin() {
      return store.getters.isSignin;
    },
  },
};
</script>
