<template>
	<div class="announcement">
		<v-card class="bg-primary text-light text-left">お知らせ</v-card>
	</div>
</template>

<script>
import store from '../store';
export default {
	name: 'Announcement',
	computed: {
		getname() {
			return store.getters.name;
		},
	},
};
</script>
